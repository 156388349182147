import styled from 'styled-components'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const CookiesContainer = styled.div`
  display: flex;
  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}
`

export const Cell = styled.div`
  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}
`

export const CookieButton = styled.button`
border: none;
cursor: pointer;
font-size: 16px;
${typography}
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${border}
`
