import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '../../TextBlock/styles'
import LoginForm from './LoginForm'
import Cookies from '../../AcceptCookies'
import LoginHeader from './LoginHeader'

const LoginColumn = ({ slug, formValues, validate, ...rest }) => {
  return (
    <StyledContainer textAlign="left" width={[1, 1, 1, 1 / 2]}>
      <LoginHeader {...rest} />
      <LoginForm
        formValues={formValues}
        validate={validate}
        slug={slug}
        {...rest}
      />
    </StyledContainer>
  )
}

LoginColumn.propTypes = {
  slug: PropTypes.string,
  validate: PropTypes.func,
  formValues: PropTypes.shape({
    initValues: PropTypes.object,
    formValueArr: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default LoginColumn
