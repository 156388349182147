import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledTitle } from '../TextBlock/styles'
import LoginForm from './components/LoginForm'

const LoginDevBlock = ({ ctaLink, ctaText, placeholderText, ...rest }) => {
  return (
    <StyledContainer
      display="flex"
      flexWrap="wrap"
      minHeight="75vh"
      alignItems="center"
    >
      <StyledContainer width={[1, 1, 1, 3 / 4]} px={[4, 4, 6]}>
        <StyledTitle
          fontSize={2}
          lineHeight={1.2}
          fontWeight={900}
          color="dark.text.primary"
          paddingBottom="15px"
          fontFamily="headings"
        >
          WELCOME
        </StyledTitle>
        <LoginForm
          slug={ctaLink}
          ctaText="ACCESS SITE"
          placeholderText={null}
          alignItems="flex-end"
          {...rest}
        />
      </StyledContainer>
    </StyledContainer>
  )
}

LoginDevBlock.propTypes = {
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  placeholderText: PropTypes.string,
}

export default LoginDevBlock
