import React from 'react'
import PropTypes from 'prop-types'
import { parseBackground } from '../BladeDefaultContainer/BladeDefaultGatsbyCDAContainer'
import { validateInput, createFormValues } from '@common/utils'
import { useLocale, useLogin } from '@common/hooks'
import Redirect from '@common/components/Redirect'
import LoginBlock from '@common/components/Login/LoginBlock'
import LoginDevBlock from '@common/components/Login/LoginDevBlock'
import { navigate } from 'gatsby'

const LoginBlockGatsbyCDAContainer = ({
  ctaLink,
  heroImage,
  errorMessage,
  background,
  autoLogin = false,
  accessCode = '',
  ...rest
}) => {
  const { locale } = useLocale()
  const { auth } = useLogin()
  const { setIsAuthenticated, isAuthenticated, production, setProd } = auth

  const bg = parseBackground(background)

  const formValues = createFormValues(
    production,
    errorMessage,
    autoLogin,
    accessCode
  )

  const validate = values => {
    const errors = {}
    formValues.formValueArr.forEach(v => {
      if (!values[v]) {
        // validating field has been touched but field has no input
        errors[v] = 'Required'
      } else if (
        validateInput(
          values[v],
          formValues.initVals.validSchema[v],
          production,
          formValues.initVals.valType[v]
        )
      ) {
        errors[v] = validateInput(
          values[v],
          formValues.initVals.validSchema[v],
          production,
          formValues.initVals.valType[v]
        )
      }
    })
    return errors
  }

  const handleSubmit = (values, actions) => {
    setTimeout(() => {
      if (isAuthenticated) {
        navigate(ctaLink?.[0]?.slug)
        actions.setSubmitting(false)
      } else if (production) {
        setIsAuthenticated(true)
      } else {
        setProd(true)
        actions.setSubmitting(false)
      }
    }, 500)
  }

  const columns = heroImage && [
    {
      layout: 'Transparent Background',
      mainContent: [{ ...heroImage?.[0], src: heroImage?.[0]?.source?.fluid }],
      width: 100,
    },
  ]
  const localeSlug =
    locale === 'en-US' ? ctaLink?.[0]?.slug : `/${locale}/${ctaLink?.[0]?.slug}`

  if (isAuthenticated) return <Redirect to={localeSlug} />

  if (production) {
    return (
      <LoginBlock
        handleSubmit={handleSubmit}
        columns={columns}
        validate={validate}
        bg={bg}
        formValues={formValues}
        ctaLink={ctaLink?.[0]?.slug}
        {...rest}
      />
    )
  }
  return (
    <LoginDevBlock
      handleSubmit={handleSubmit}
      validate={validate}
      formValues={formValues}
      ctaLink={ctaLink?.[0]?.slug}
      {...rest}
    />
  )
}

LoginBlockGatsbyCDAContainer.propTypes = {
  ctaLink: PropTypes.arrayOf(PropTypes.object),
  heroImage: PropTypes.arrayOf(PropTypes.object),
  errorMessage: PropTypes.string,
  background: PropTypes.arrayOf(PropTypes.object),
}

export default LoginBlockGatsbyCDAContainer
