import React from 'react'
import PropTypes from 'prop-types'
import { StyledTitle, StyledDiv } from '../../TextBlock/styles'

const Eyebrow = ({ children, ...rest }) => {
  if (!children) {
    return null
  }
  return <StyledDiv {...rest}>{children}</StyledDiv>
}

const applyTitleDecorations = text => {
  if (!text) return null

  text = text
    .split(' | ')
    .join(`<div class="line-container"><div class="line"></div></div>`)

  return text
}
const LoginHeader = ({ eyebrow, title, subtitle }) => {
  return (
    <>
      <Eyebrow
        fontSize={7}
        lineHeight={1.25}
        letterSpacing="0.2rem"
        color="dark.text.secondary"
        fontWeight="light"
        marginBottom={2}
      >
        {eyebrow}
      </Eyebrow>
      <StyledTitle
        fontSize={2}
        lineHeight={1.2}
        fontWeight={900}
        color="dark.text.primary"
        paddingBottom="15px"
        fontFamily="headings"
        marginBottom={2}
        dangerouslySetInnerHTML={{
          __html: applyTitleDecorations(title),
        }}
      />
      <Eyebrow
        fontSize={3}
        lineHeight={1.25}
        letterSpacing="0.2rem"
        color="dark.text.secondary"
        fontWeight="light"
      >
        {subtitle}
      </Eyebrow>
    </>
  )
}

LoginHeader.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default LoginHeader
