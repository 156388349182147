import React from 'react'
import PropTypes from 'prop-types'
import { BladeDefault } from '../Blade'
import { StyledContainer } from '../TextBlock/styles'
import { ColumnBlock } from '../ColumnBlock'
import LoginColumn from './components/LoginColumn'

const LoginBlock = ({ bg, ctaLink, columns = [], ...rest }) => {
  return (
    <BladeDefault {...bg}>
      <StyledContainer
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        width={[1]}
        fontFamily="headings"
      >
        <LoginColumn slug={ctaLink} {...rest} />
        <StyledContainer textAlign="left" width={[1, 1, 1, 1 / 2]}>
          <ColumnBlock
            columns={columns}
            horizontalAlignment="center"
            maxWidth="100%"
          />
        </StyledContainer>
      </StyledContainer>
    </BladeDefault>
  )
}

LoginBlock.propTypes = {
  ctaLink: PropTypes.string,
  columns: PropTypes.array,
  bg: PropTypes.object,
}

export default LoginBlock
