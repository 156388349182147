import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../components/Layout/styles'
import Container from '@common/components/Container'
import theme from '../themes'
import Footer from '../components/Footer'
import { LocaleProvider, useLogin } from '@common/hooks'
import Navigation from '../components/Navigation'
import { parseQueryString } from '../../../common/utils'
import LoginBlockGatsbyCDAContainer from '../containers/LoginBlockContainer/LoginBlockGatsbyCDAContainer'
import config from '../../config'
import TitleAndMetaTags from '@common/components/TitleAndMetaTags'

const useQueryString = ({
  queryStringKey = 'key',
  queryStringTargetValueArray = ['value'],
  queryStringDefaultValue = '',
}) => {
  const [qsValue, setQsValue] = useState(queryStringDefaultValue)
  const [valueIsInTargetValueArray, setValueIsInTargetValueArray] = useState(
    false
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const parsedValue = parseQueryString(queryStringKey)
      setQsValue(parsedValue)

      if (queryStringTargetValueArray.includes(parsedValue)) {
        setValueIsInTargetValueArray(true)
      }
    }
  }, [setQsValue, setValueIsInTargetValueArray])

  return [qsValue, setQsValue, valueIsInTargetValueArray]
}

const Login = ({ data, pageContext }) => {
  const { auth } = useLogin()
  const accessCodes = process.env.GATSBY_LOGIN_PASS_ACCESS_CODES?.split(',')
  const [qsValue, setQsValue, valueIsInTargetValueArray] = useQueryString({
    queryStringKey: 'passcode',
    queryStringTargetValueArray: accessCodes,
  })
  const { production } = auth
  const { preview, locale } = pageContext
  const loginInfo = data?.login?.nodes?.[0]
  const localeSlug = locale === 'en-US' ? '' : `/${locale}`
  const homeSlug = preview
    ? `${localeSlug}/preview/?t=xbox&id=4c4o2bt9cRrhdKVqGZlnlO`
    : `${localeSlug || '/'}`

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider locale={locale}>
        <TitleAndMetaTags
          config={config}
          lang={pageContext?.locale}
          metaTitle={loginInfo?.metaTitle}
          metaDescription={loginInfo?.metaDescription}
        />
        <GlobalStyle />
        {production && (
          <Navigation preview={preview} homeSlug={homeSlug} loginPage />
        )}
        <div style={{ height: '61px' }} />
        <Container>
          <LoginBlockGatsbyCDAContainer
            {...loginInfo}
            autoLogin={valueIsInTargetValueArray}
            accessCode={qsValue}
          />
        </Container>
        {production && <Footer preview={preview} homeSlug={homeSlug} />}
      </LocaleProvider>
    </ThemeProvider>
  )
}

Login.propTypes = {
  data: PropTypes.object,
}

export default Login

export const query = graphql`
  query Login($id: String!, $locale: String!) {
    login: allContentfulLogin(
      filter: { contentful_id: { eq: $id }, node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        eyebrow
        subtitle
        ctaText
        ctaLink {
          slug
        }
        heroImage {
          ...imageFields
        }
        placeholderText
        errorMessage
        background {
          ...backgroundFields
        }
        metaDescription
        metaTitle
      }
    }
  }
`
