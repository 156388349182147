import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors)
      if (keys.length > 0) {
        const selectorAll = keys.map(k => `[name=${k}]`)
        const errorElement = document.querySelector(selectorAll[0])
        if (errorElement) {
          selectorAll.forEach(s => {
            const errors = document.querySelector(s)
            errors.style.border = '1px solid red'
          })
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default FocusError
