import React from 'react'
import { PropTypes } from 'prop-types'
import { Formik, ErrorMessage } from 'formik'
import {
  Container,
  StyledForm,
  ButtonContainer,
  StyledField,
  StyledMessage,
} from '../../Form/style'
import { Button } from '../../ButtonAccessible'
import FocusError from './ErrorFocus'

const StatusMessage = ({ children, success, id }) => {
  return (
    <StyledMessage
      color={success ? 'green' : 'red'}
      role="status"
      fontSize={[12]}
      id={!success ? `${id}-error` : 0}
      m={[1]}
    >
      {children}
    </StyledMessage>
  )
}

const LoginForm = ({
  title,
  formValues = {},
  handleSubmit,
  ctaText,
  placeholderText,
  validate,
  alignItems,
}) => {
  const { initVals, formValueArr } = formValues
  return (
    <Container width={[1]} my="40px">
      <Formik
        initialValues={initVals.initValues}
        validateOnChange={false}
        validateOnBlur={false}
        validate={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          errors,
          values,
          initialValues,
          handleBlur,
          handleChange,
          touched,
        }) => {
          return (
            <StyledForm width={[1]} role="form" aria-label={title}>
              <Container
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Container width={[4 / 5, 3 / 5]} pr={[3]}>
                  {formValueArr?.map(value => (
                    <Container key={value} width={[1]} height="80px">
                      <label htmlFor={value}>
                        <StyledField
                          mb={[2]}
                          letterSpacing="1px"
                          fontWeight="bold"
                          height="46px"
                          color="white"
                          fontSize="15px"
                          padding="13px 20px"
                          bg="black"
                          fontFamily="headings"
                          border="1px solid #ffffff80"
                          aria-label={initVals?.type?.[value]}
                          type={initVals?.type?.[value]}
                          name={value}
                          placeholder={
                            (!initVals.initValues[value] &&
                              initVals?.placeholder?.[value]) ||
                            placeholderText
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[value]}
                          width={[1]}
                          aria-invalid={
                            errors[value] && touched[value] ? true : null
                          }
                          aria-describedby={
                            errors[value] && touched[value]
                              ? `login-error`
                              : null
                          }
                          style={{ opacity: '50%' }}
                        />
                      </label>
                      <ErrorMessage name={value}>
                        {msg => <StatusMessage id={value}>{msg}</StatusMessage>}
                      </ErrorMessage>
                    </Container>
                  ))}
                </Container>
                <ButtonContainer
                  display="flex"
                  alignItems={alignItems}
                  mt={[3, 0]}
                  width={[1, 2 / 5]}
                  height={['auto', '126px']}
                >
                  <Button type="submit" role="button" height="46px">
                    {isSubmitting ? 'Loading...' : ctaText}
                  </Button>
                </ButtonContainer>
                <FocusError />
              </Container>
            </StyledForm>
          )
        }}
      </Formik>
    </Container>
  )
}

LoginForm.propTypes = {
  title: PropTypes.string,
  formValues: PropTypes.shape({
    initValues: PropTypes.object,
    formValueArr: PropTypes.arrayOf(PropTypes.string),
  }),
  handleSubmit: PropTypes.func,
  ctaText: PropTypes.string,
  placeholderText: PropTypes.string,
  validate: PropTypes.func,
  alignItems: PropTypes.string,
}

export default LoginForm
